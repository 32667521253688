import React from "react"
import Container from "react-bootstrap/Container"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import AlertUnfinishedPage from "../../components/AlertUnfinishedPage"
import Seo from "../../components/Seo"

export const Head = () => {
  return <Seo customTitle="Touristik" />
}

export default function touristik() {
  return (
    <Layout>
      <Container className="my-5">
        <AlertUnfinishedPage />
        <PageTitle title="Touristik" />
        <p className="lh-text mb-5 lead font-weight-normal">
          In den 60er Jahren waren die MSF sehr stark im Tourensport vertreten.
          Gern erinnern sich die Mitglieder noch an die Sternfahrten nach
          Johannesberg, Zotzenbach, Emden, Gefrees, Landhadeln und 1963 sogar
          nach Gent in Belgien zurück.
        </p>
      </Container>
    </Layout>
  )
}
