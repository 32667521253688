import React, { useState } from "react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import { ConeStriped } from "react-bootstrap-icons"

export default function AlertUnfinishedPage() {
  // Show the alerts by default
  const [show, setShow] = useState(true)

  return (
    <Alert variant="warning" className="p-5 mb-5 shadow-sm rounded" show={show}>
      <div className="d-block d-md-flex align-items-center">
        <ConeStriped className="display-4 mr-5 mb-3 mb-md-0" />
        <Alert.Heading className="font-weight-bold">
          Diese Seite wird aktuell bearbeitet und ist noch nicht fertig!
        </Alert.Heading>
      </div>
      <hr />
      <p>
        Wir werden so schnell wie möglich, eine aktualisierte Version dieser
        Seite hochladen. Besuchen Sie doch inzwischen eine der anderen Seiten
        unserer Webseite.
      </p>
      <Button
        variant="link"
        className="font-weight-bold px-0 text-left"
        style={{ color: "#856404" }}
        onClick={() => setShow(false)}
      >
        Klicken Sie hier, um die Mitteilung zuschließen.
      </Button>
    </Alert>
  )
}
